@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.gradient-bg {
  background-image: linear-gradient(to right, #001f3f, #0053a6, #030637, #004d00, #005B41, #663300, #995200, #4b0082, #008170, #19376D);
  animation: gradientMove 12s linear infinite;
  background-size: 1000% 100%;
}


.gradient-text {
  color: #fff; 
}